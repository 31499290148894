// src/components/Header.js
import React from "react";
import styles from "./Header.module.css"; // Importing the CSS module for styling

// Defining the Header component
const Header = () => {
  return (
    <header className={styles.header}>
      <nav>
        <ul className={styles.navList}>
          <li className={styles.navItem}>
            <a href="/" className={styles.navLink}>
              Home
            </a>
          </li>
          <li className={styles.navItem}>
            <a href="/about" className={styles.navLink}>
              About
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
