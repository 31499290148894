// src/AboutPage.js
import React from "react";

function AboutPage() {
  return (
    <div>
      <h1>About Pageasdfsadf</h1>
      <p>Welcome to the about page of our application.</p>
    </div>
  );
}

export default AboutPage;
