import React from "react";

function HomePage() {
  const today = new Date().toLocaleDateString(); // Gets the current date

  return (
    <div>
      <h1>Welcome to My React App!</h1>
      <p>Today's date is {today}.</p>
      <button onClick={() => alert("Navigating...")}>Go to About Page</button>
    </div>
  );
}

export default HomePage;
