// src/App.js
import React from "react";
import "./App.css";
import HomePage from "./HomePage";
import AboutPage from "./AboutPage";
import Header from "./components/Header";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Create a router instance
const router = createBrowserRouter([
  {
    path: "/", // Root path
    element: <HomePage />, // The element that should be rendered at the root path
  },
  {
    path: "/about", // About path
    element: <AboutPage />,
  },
]);

function App() {
  return (
    <>
      <Header /> {/* Add the Header component here */}
      <RouterProvider router={router} />{" "}
      {/* Using RouterProvider to supply the router configuration to the app */}
    </>
  );
}

export default App;
